import React, { useState } from "react"
import SEO from "./seo"

const HeraldLanding = ({ locationTracker }) => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }

  const onChangeValue = event => {
    setLoginAs(event.target.value)
  }

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Herald International College Fuse Classroom Login"}
        fbDes={"Herald International College Fuse Classroom Login"}
        twitDesc={"Herald International College Fuse Classroom Login"}
        seoLink={"https://hic.fuseclassroom.com/"}
        ogImage={"https://www.heraldintlcollege.edu.np/admin/uploads/logo/44080-logoleft.png"}
      />

      <div className="herald-landing">
        {/* left side */}
        <div className="left-side">
          <div className="left-content">
            <img src="./images/herald/Logo.png" className="logo" alt="logo" />
            <div className="title">Quality Education For Excellence</div>
            <div className="login-area">
              <div className="login-details">
                <h5>Select your role to login</h5>
                <form onSubmit={formSubmit}>
                  <div className="forms">
                    <div className="form-check">
                      <input
                        type="radio"
                        id="student"
                        name="loginAs"
                        checked={loginAs === `${locationTracker}.student.fuseclassroom.com`}
                        value={`${locationTracker}.student.fuseclassroom.com`}
                        onChange={onChangeValue}
                      />
                      <label htmlFor="student">
                        <img src="/images/herald/student.svg" alt="Student" />
                        Student
                      </label>
                      <div className="check">
                        <img src="/images/herald/check-ic.svg" alt="Check" />
                      </div>
                    </div>

                    <div className="form-check">
                      <input
                        type="radio"
                        id="other"
                        name="loginAs"
                        value={`${locationTracker}.admin.fuseclassroom.com`}
                        checked={loginAs === `${locationTracker}.admin.fuseclassroom.com`}
                        onChange={onChangeValue}
                      />
                      <label htmlFor="other">
                        <img src="/images/herald/others.svg" alt="Other" />
                        Other
                      </label>
                      <div className="check">
                        <img src="/images/herald/check-ic.svg" alt="Check" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <button type="submit" className={`btn-log ${loginAs === "" ? "btn-disabled" : ""}`}>
                      Login
                    </button>
                  </div>
                </form>

                <div className="help">
                  <img src="/images/herald/help.svg" className="support" alt="help" />
                  <span>
                    For help email{" "}
                    <a href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com">
                      support@fusemachines.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* right side */}
        <div className="right-side"></div>
      </div>
    </>
  )
}

export default HeraldLanding
