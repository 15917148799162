import React, { useState } from "react"
import SEO from "../components/seo"

const BostonLandingPage = ({ locationTracker }) => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }

  const onChangeValue = event => setLoginAs(event.target.value)

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Boston International College Fuse Classroom Login"}
        fbDes={"Boston International College Fuse Classroom Login"}
        twitDesc={"Boston International College Fuse Classroom Login"}
        seoLink={""}
        ogImage={""}
      />

      <div className="boston-landing">
        <div className="d-flex h-100">
          <div className="left-side">
            <div className="left-top">
              <img alt="boston-left" src="/images/boston/Boston-left-top.png" />
            </div>
            <div className="left-content">
              <div className="logo">
                <img alt="boston-logo" className="h-full" src="/images/boston/boston-logo.png" />
              </div>
              <div className="title">
                Center of Excellence
                <br /> in Management Studies
              </div>
              <div className="login-details">
                <h5>Login as</h5>
                <form onSubmit={formSubmit}>
                  <div className="forms">
                    <div className="form-check same">
                      <input
                        type="radio"
                        id="student"
                        name="loginAs"
                        checked={loginAs === `${locationTracker}.student.fuseclassroom.com`}
                        value={`${locationTracker}.student.fuseclassroom.com`}
                        onChange={onChangeValue}
                      />
                      <label htmlFor="student">Student</label>
                    </div>
                    <div className="form-check same">
                      <input
                        type="radio"
                        id="other"
                        name="loginAs"
                        value={`${locationTracker}.admin.fuseclassroom.com`}
                        checked={loginAs === `${locationTracker}.admin.fuseclassroom.com`}
                        onChange={onChangeValue}
                      />
                      <label htmlFor="other">Other</label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={`btn-log ${loginAs === "" ? "btn-disabled" : ""}`}
                    disabled={loginAs === ""}
                  >
                    Login
                  </button>
                </form>
              </div>
              <div className="help">
              <img src="/images/boston/help.svg" className="support" alt="help" />
              <span>
                For help email{" "}
                <a href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com">
                  support@fusemachines.com
                </a>
              </span>
            </div>
            </div>
            <div className="background-img">
              <img alt="boston-bg-2" src="/images/boston/Boston-left-bg.jpg" />
            </div>
          </div>

          <div className="right-side">
            <div className="website">
              <div className="link">www.bostoncollege.edu.np</div>
            </div>
          </div>
        </div>
        <div className="right-content">
          <div>
            <div className="faculty">MBA | BBA | BBA-BI | BCIS</div>
            <div className="uni"> (Affiliated to Pokhara University)</div>
            <div className="contact">
              <p>
                <img alt="boston-phone" src="/images/boston/phone-call.svg" />{" "}
                <span> +977-056-526203, 531362, Fax: 531366</span>
              </p>
              <p>
                <img alt="boston-email" src="/images/boston/email.svg" />
                <span>info@bostoncollege.edu.np</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BostonLandingPage
