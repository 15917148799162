import React from "react"
import SEO from "../components/seo"

const Loading = () => (
  <>
    <SEO
      title={"AI Enabled Education Platform"}
      titleDesc={"Fuse Classroom Login"}
      fbDes={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
      twitDesc={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
      seoLink={""}
      ogImage={"https://fuseclassroom.com/images/ogimage.jpg"}
    />

    <div className="">
      <svg
        className="svg-loader"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="500"
        height="500"
        viewBox="0 0 500 500"
        id="loader"
      >
        <polygon className="segment" points="250,250 250,0 465,126" />
        <polygon className="segment" points="250,250 465,126 465,375" />
        <polygon className="segment" points="250,250 465,375 250,500" />
        <polygon className="segment" points="250,250 250,500 36,375" />
        <polygon className="segment" points="250,250 36,375 36,126" />
        <polygon className="segment" points="250,250 36,126 250,0" />
      </svg>
    </div>
  </>
)

export default Loading
