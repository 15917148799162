import React, { useState } from "react"
import SEO from "./seo"

const MalpiLanding = () => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }

  const onChangeValue = event => {
    setLoginAs(event.target.value)
  }

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Malpi International School Fuse Classroom Login"}
        fbDes={"Malpi International School Fuse Classroom Login"}
        twitDesc={"Malpi International School Fuse Classroom Login"}
        seoLink={"https://malpi.fuseclassroom.com/"}
        ogImage={"https://malpi.fuseclassroom.com/images/ogimage.jpg"}
      />
      <div className="malpi-landing">
        <div className="d-flex">
          <div className="left-side">
            <div className="title">Enquire . Endeavour . Excel</div>
            <div className="branding">
              <img alt="Malpi International School_logo" src="/images/malpi/school-logo.png" className="main-logo" />
              <div>Malpi International School</div>
              <div className="small">Panauti, Kavre, Nepal</div>
            </div>
            <div className="help">
              <span>
                For help email{" "}
                <a href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com">
                  support@fusemachines.com
                </a>
              </span>
            </div>
            <div className="login-details">
              <h5>Login as</h5>
              <form onSubmit={formSubmit}>
                <div className="forms">
                  <div className="form-check same">
                    <input
                      type="radio"
                      id="student"
                      name="loginAs"
                      checked={loginAs === "https://malpi.student.fuseclassroom.com"}
                      value="https://malpi.student.fuseclassroom.com"
                      onChange={onChangeValue}
                    />
                    <label htmlFor="student">Student</label>
                  </div>
                  <div className="form-check same">
                    <input
                      type="radio"
                      id="other"
                      name="loginAs"
                      value="https://malpi.admin.fuseclassroom.com"
                      checked={loginAs === "https://malpi.admin.fuseclassroom.com"}
                      onChange={onChangeValue}
                    />
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
                <button
                  type="submit"
                  className={`btn-log ${loginAs === "" ? "btn-disabled" : ""}`}
                  disabled={loginAs === ""}
                >
                  Login
                </button>
              </form>
            </div>
            <div className="more-contact">
              Our Contact :<a href="mailto:malpi@mos.com.np?Subject=Querries">malpi@mos.com.np</a>
            </div>
          </div>

          <div className="right-side">
            {" "}
            <img alt="Malpi School" className="img-responsive" src="/images/malpi/malpi -bgimage_ovelayed.jpg" />
            <div className="stamp">
              <img alt="Malpi School Achievement" src="/images/malpi/achivement.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MalpiLanding
