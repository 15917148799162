import React, { useState } from "react"
import SEO from "../components/seo"

const PrimeLanding = ({ locationTracker }) => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }

  const onChangeValue = event => {
    setLoginAs(event.target.value)
  }

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Prime International College Fuse Classroom Login"}
        fbDes={"Prime International College Fuse Classroom Login"}
        twitDesc={"Prime International College Fuse Classroom Login"}
        seoLink={"https://prime.fuseclassroom.com/"}
        ogImage={"https://prime.fuseclassroom.com/images/ogimage.jpg"}
      />

      <div className="prime-landing">
        <div className="login-details">
          <img src="/images/prime/prime-logo.png" className="logo" alt="logo" />
          <div className="title">Your online classes a click away</div>
          <h5>Login as</h5>
          <form onSubmit={formSubmit}>
            <div className="forms">
              <div className="form-check form-check__options">
                <input
                  type="radio"
                  id="student"
                  name="loginAs"
                  checked={loginAs === `${locationTracker}.student.fuseclassroom.com`}
                  value={`${locationTracker}.student.fuseclassroom.com`}
                  onChange={onChangeValue}
                />
                <label htmlFor="student">Student</label>
              </div>
              <div className="form-check form-check__options">
                <input
                  type="radio"
                  id="other"
                  name="loginAs"
                  value={`${locationTracker}.admin.fuseclassroom.com`}
                  checked={loginAs === `${locationTracker}.admin.fuseclassroom.com`}
                  onChange={onChangeValue}
                />
                <label htmlFor="other">Other</label>
              </div>
            </div>
            <button
              type="submit"
              className={`btn btn-log ${loginAs === "" ? "btn-disabled" : ""}`}
              disabled={loginAs === ""}
            >
              Login
            </button>
          </form>
          <div className="help">
            <img src="/images/prime/help-icon.svg" alt="logo" />
            <span>
              For help email{" "}
              <a href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com">
                support@fusemachines.com
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrimeLanding
