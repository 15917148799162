import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import OtherLanding from "../components/others-landing"
import BostonLanding from "../components/boston-landing"
import ThamesLanding from "../components/thames-landing"
import KistLanding from "../components/kist-landing"
import MalpiLanding from "../components/malpi-landing"
import Loader from "../components/loading"
import TrinityLanding from "../components/trinity-landing"
import AnkuramLanding from "../components/ankuram-landing"
import PrimeLanding from "../components/prime-landing"
import HeraldLanding from "../components/herald-landing"
import MargaLanding from "../components/marga"
import WoolwichLanding from "../components/woolwich"

import FirstTemplate from "../components/Templates/FirstTemplate"
import SecondTemplate from "../components/Templates/SecondTemplate"
import ThirdTemplate from "../components/Templates/ThirdTemplate"
import TerminatedPage from "../components/TerminatedPage"

import axios from "../service"

const TEMPLATE1 = "TEMPLATE1"
const TEMPLATE2 = "TEMPLATE2"
const TEMPLATE3 = "TEMPLATE3"
const TERMINATED = "school_terminated"

const IndexPage = () => {
  const [locationTracker, setLocationTracker] = useState(null)
  const [schoolData, setSchoolData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isTerminated, setIsTerminated] = useState(false)
  useEffect(() => {
    const browser = typeof window !== "undefined" && window
    if (browser) {
      if (browser.location.href.includes("thames")) setLocationTracker("https://mythames")
      else if (browser.location.href.includes("kist")) setLocationTracker("https://kist")
      else {
        setLocationTracker(browser.location.href.split(".")[0])
      }
    }
  }, [])

  useEffect(() => {
    axios
      .get("/api/v2/landingPage/")
      .then(response => {
        if (response.data !== "") {
          setSchoolData(response.data)
        }
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response && error.response.status === 400 && error.response.data.message === TERMINATED) {
          setIsTerminated(true)
        }
      })
  }, [])
  if (isTerminated) {
    return (
      <Layout>
        <TerminatedPage />
      </Layout>
    )
  } else if (locationTracker === "https://mythames") {
    return (
      <Layout>
        <ThamesLanding />
      </Layout>
    )
  } else if (locationTracker === "https://kist") {
    return (
      <Layout>
        <KistLanding />
      </Layout>
    )
  } else if (locationTracker === "https://malpi") {
    return (
      <Layout>
        <MalpiLanding />
      </Layout>
    )
  } else if (locationTracker === "https://trinity") {
    return (
      <Layout>
        <TrinityLanding locationTracker={locationTracker} />
      </Layout>
    )
  } else if (locationTracker === "https://ankuram") {
    return (
      <Layout>
        <AnkuramLanding locationTracker={locationTracker} />
      </Layout>
    )
  } else if (locationTracker === "https://bic") {
    return (
      <Layout>
        <BostonLanding locationTracker={locationTracker} />
      </Layout>
    )
  } else if (locationTracker === "https://prime") {
    return (
      <Layout>
        <PrimeLanding locationTracker={locationTracker} />
      </Layout>
    )
  } else if (locationTracker === "https://hic") {
    return (
      <Layout>
        <HeraldLanding locationTracker={locationTracker} />
      </Layout>
    )
  } else if (locationTracker === "https://marga") {
    return (
      <Layout>
        <MargaLanding />
      </Layout>
    )
  } else if (locationTracker === "https://twc") {
    return (
      <Layout>
        <WoolwichLanding />
      </Layout>
    )
  } else if (!locationTracker || isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    )
  } else if (schoolData?.selectedTemplate) {
    if (schoolData.selectedTemplate === TEMPLATE1) {
      return (
        <Layout>
          <FirstTemplate schoolData={schoolData} locationTracker={locationTracker} />
        </Layout>
      )
    } else if (schoolData.selectedTemplate === TEMPLATE2) {
      return (
        <Layout>
          <SecondTemplate schoolData={schoolData} locationTracker={locationTracker} />
        </Layout>
      )
    } else if (schoolData.selectedTemplate === TEMPLATE3) {
      return (
        <Layout>
          <ThirdTemplate schoolData={schoolData} locationTracker={locationTracker} />
        </Layout>
      )
    }
  } else {
    return (
      <Layout>
        <OtherLanding schoolData={schoolData} locationTracker={locationTracker} />
      </Layout>
    )
  }
}

export default IndexPage
