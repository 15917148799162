import React, { useState } from "react"
import { LightenDarkenColor } from "lighten-darken-color"
import SEO from "../seo"
import { HM, MCC, ABERCROMBIE } from "../../constants"

const ThirdTemplate = ({ schoolData, locationTracker }) => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }
  const primaryColor = schoolData.primaryColor
  const bgImg = {
    backgroundImage: `url(${schoolData.backgroundImage})`,
    boxShadow: `inset 0 0 0 2000px ${primaryColor}66`,
  }
  const bgLight = {
    backgroundColor: schoolData.secondaryColor ? schoolData.secondaryColor : LightenDarkenColor(primaryColor, 180),
  }
  const linkColor = {
    color: primaryColor,
  }
  const bgPrimary = {
    backgroundColor: primaryColor,
  }
  const onChangeValue = event => {
    setLoginAs(event.target.value)
  }
  const parentUrl = schoolData?.parentUrl
  const isHMLanding = () => !!locationTracker.includes(HM)
  const isAI2Go = () => !!(schoolData?.tagLine?.length && schoolData.tagLine.toLowerCase()?.includes("ai2go"))
  const isMCCLanding = () => !!locationTracker.includes(MCC)
  const isAbercrombie = () => !!locationTracker.includes(ABERCROMBIE)

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Fuse Classroom Login"}
        fbDes={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        twitDesc={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        seoLink={"https://academy.fuseclassroom.com/"}
        ogImage={"https://fuseclassroom.com/images/ogimage.jpg"}
      />

      <div className="standard-landing-three" style={{ backgroundColor: "#f4f5f5" }}>
        <style dangerouslySetInnerHTML={{ __html: schoolData.dynamicCSS }} />
        {/* left side */}
        <div className="left-side" style={bgImg} id="dyn-container">
          <div className="left-content" id="dyn-left-content">
            {schoolData?.dynamicHTML ? (
              <div dangerouslySetInnerHTML={{ __html: schoolData?.dynamicHTML }} id="dyn-div"></div>
            ) : (
              <div>
                <img src={schoolData.logo} className="logo" alt="logo" id="dyn-logo" />
                <div className="title" id="dyn-title">
                  {schoolData.tagLine}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* right side */}
        <div className="right-side" style={isAI2Go() ? { backgroundColor: "#f4f5f5" } : undefined}>
          <div className={schoolData.secondaryColor ? schoolData.secondaryColor : "login-details"}>
            <h5>Select your role to login</h5>
            <form onSubmit={formSubmit}>
              <div className="forms">
                {schoolData?.loginTypes ? (
                  schoolData.loginTypes.map(type => (
                    <div className="form-check">
                      <input
                        type="radio"
                        id={type?.label}
                        name="loginAs"
                        checked={loginAs === type?.redirectUrl}
                        value={type?.redirectUrl}
                        onChange={onChangeValue}
                      />
                      <label htmlFor={type?.label}>
                        <img src={type?.iconUrl} alt={type?.label} />
                        {type?.label}
                      </label>
                      <div className="check">
                        <img src="/images/check-ic.svg" alt="Check" />
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="form-check">
                      <input
                        type="radio"
                        id="student"
                        name="loginAs"
                        checked={loginAs === schoolData.studentUrl}
                        value={schoolData.studentUrl}
                        onChange={onChangeValue}
                      />
                      <label htmlFor="student">
                        <img src="/images/student-standard.svg" alt="Student" />

                        {isAI2Go() || isHMLanding() || isMCCLanding() || isAbercrombie() ? "Participant" : "Student"}
                      </label>
                      <div className="check">
                        <img src="/images/check-ic-standard.svg" alt="Check" />
                      </div>
                    </div>
                    {parentUrl?.length && (
                      <div className="form-check">
                        <input
                          type="radio"
                          id="parent"
                          name="loginAs"
                          checked={loginAs === parentUrl}
                          value={parentUrl}
                          onChange={onChangeValue}
                        />
                        <label htmlFor="parent">
                          <img src="/images/parents-standard.svg" alt="Parent" />
                          Parent
                        </label>
                        <div className="check">
                          <img src="/images/check-ic-standard.svg" alt="Check" />
                        </div>
                      </div>
                    )}

                    <div className="form-check">
                      <input
                        type="radio"
                        id="other"
                        name="loginAs"
                        value={schoolData.adminUrl}
                        checked={loginAs === schoolData.adminUrl}
                        onChange={onChangeValue}
                      />
                      <label htmlFor="other">
                        <img src="/images/others-standard.svg" alt="Other" />
                        Other
                      </label>
                      <div className="check">
                        <img src="/images/check-ic-standard.svg" alt="Check" />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <button type="submit" className={`btn-log ${loginAs === "" ? "btn-disabled" : ""}`} style={bgPrimary}>
                Login
              </button>
            </form>

            <div className="help">
              <img src="/images/help-standard.svg" className="support" alt="help" />
              <span>
                For help email{" "}
                <a
                  href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com"
                  style={linkColor}
                >
                  support@fusemachines.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThirdTemplate
