import React, { useState } from "react"
import SEO from "../components/seo"

const IndexPage = () => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }

  const onChangeValue = event => {
    setLoginAs(event.target.value)
  }

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Thames International College Fuse Classroom Login"}
        fbDes={"Thames International College Fuse Classroom Login"}
        twitDesc={"Thames International College Fuse Classroom Login"}
        seoLink={"https://mythames.fuseclassroom.com/"}
        ogImage={"https://mythames.fuseclassroom.com/images/ogimage.jpg"}
      />

      <div className="thames-landing">
        <div className="d-flex">
          <div className="left-side">
            <div className="left-content">
              <img alt="thames-logo" src="/images/thames-logo.png" />
              <div className="title">Digital College Experience</div>

              <div className="login-details">
                <h5>Login as</h5>
                <form onSubmit={formSubmit}>
                  <div className="forms">
                    <div className="form-check same">
                      <input
                        type="radio"
                        id="student"
                        name="loginAs"
                        checked={loginAs === "https://mythames.student.fuseclassroom.com"}
                        value="https://mythames.student.fuseclassroom.com"
                        onChange={onChangeValue}
                      />
                      <label htmlFor="student">Student</label>
                    </div>
                    <div className="form-check same">
                      <input
                        type="radio"
                        id="other"
                        name="loginAs"
                        value="https://mythames.admin.fuseclassroom.com"
                        checked={loginAs === "https://mythames.admin.fuseclassroom.com"}
                        onChange={onChangeValue}
                      />
                      <label htmlFor="other">Other</label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={`btn-log ${loginAs === "" ? "btn-disabled" : ""}`}
                    disabled={loginAs === ""}
                  >
                    Login
                  </button>
                </form>
              </div>
              <div className="help">
                <img alt="help-icon" src="/images/help-icon.svg" />
                <span>
                  For help email{" "}
                  <a href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com">
                    support@fusemachines.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="right-side">
            {" "}
            <img alt="bg-thames" className="img-responsive" src="/images/bgthames.png" />
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexPage
