import React, { useState } from "react"
import SEO from "../components/seo"

const Woolwich = () => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }

  const onChangeValue = event => {
    setLoginAs(event.target.value)
  }

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Fuse Classroom Login"}
        fbDes={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        twitDesc={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        seoLink={"https://academy.fuseclassroom.com/"}
        ogImage={"https://fuseclassroom.com/images/ogimage.jpg"}
      />

      <div className="woolwich-landing">
        {/* left side */}
        <div className="background">
          <div className="white-block">
            <div className="logo">
              <img src="/images/woolwich/TWC Logo with tagline.png" alt="logo" />
            </div>
            <div className="title">Study. Accomplish. Inspire.</div>

            <div className="login-details">
              <h5>Login as</h5>

              <form onSubmit={formSubmit}>
                <div className="forms">
                  <div className="form-check same">
                    <input
                      type="radio"
                      id="student"
                      name="loginAs"
                      checked={loginAs === `https://twc.student.fuseclassroom.com`}
                      value={`https://twc.student.fuseclassroom.com`}
                      onChange={onChangeValue}
                    />
                    <label htmlFor="student">Student</label>
                  </div>
                  <div className="form-check same">
                    <input
                      type="radio"
                      id="other"
                      name="loginAs"
                      value={`https://twc.admin.fuseclassroom.com`}
                      checked={loginAs === `https://twc.admin.fuseclassroom.com`}
                      onChange={onChangeValue}
                    />
                    <label htmlFor="other">Other</label>
                  </div>
                </div>
                <button
                  type="submit"
                  className={`btn-log ${loginAs === "" ? "btn-disabled" : ""}`}
                  disabled={loginAs === ""}
                >
                  Login
                </button>
              </form>
              <div className="help">
                <img src="/images/help-standard.svg" className="support" alt="help" />
                <span>
                  For help email{" "}
                  <a href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com">
                    support@fusemachines.com
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Woolwich
