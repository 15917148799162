import React, { useState } from "react"
import SEO from "../components/seo"
import { FUSEAI, HM, MCC } from "../constants"

const OtherLanding = ({ schoolData, locationTracker }) => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }

  const onChangeValue = event => {
    setLoginAs(event.target.value)
  }

  const isFuseAILanding = () => !!locationTracker.includes(FUSEAI)
  const isHMLanding = () => !!locationTracker.includes(HM)
  const isMCCLanding = () => !!locationTracker.includes(MCC)

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Fuse Classroom Login"}
        fbDes={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        twitDesc={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        seoLink={"https://academy.fuseclassroom.com/"}
        ogImage={"https://fuseclassroom.com/images/ogimage.jpg"}
      />

      <div className="login-landing">
        {/* left side */}
        <div className="left-side">
          <div className="left-content">
            <img src="./images/logo-white.svg" className="logo" alt="logo" />
            <div className="title">AI Enabled Education Platform</div>
            <div className="sub-title">Start your Digital College Experience.</div>
          </div>
        </div>

        {/* right side */}
        <div className="right-side">
          <div className="login-details">
            <h5>Select your role to login</h5>
            <form onSubmit={formSubmit}>
              <div className="forms">
                {schoolData?.loginTypes ? (
                  schoolData.loginTypes.map(type => (
                    <div className="form-check">
                      <input
                        type="radio"
                        id={type?.label}
                        name="loginAs"
                        checked={loginAs === type?.redirectUrl}
                        value={type?.redirectUrl}
                        onChange={onChangeValue}
                      />
                      <label htmlFor={type?.label}>
                        <img src={type?.iconUrl} alt={type?.label} />
                        {type?.label}
                      </label>
                      <div className="check">
                        <img src="/images/check-ic.svg" alt="Check" />
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="form-check">
                      <input
                        type="radio"
                        id="student"
                        name="loginAs"
                        checked={loginAs === `${locationTracker}.student.fuseclassroom.com`}
                        value={`${locationTracker}.student.fuseclassroom.com`}
                        onChange={onChangeValue}
                      />
                      <label htmlFor="student">
                        <img src="/images/student.svg" alt="Student" />
                        {isFuseAILanding() || isHMLanding() || isMCCLanding() ? "Participant" : "Student"}
                      </label>
                      <div className="check">
                        <img src="/images/check-ic.svg" alt="Check" />
                      </div>
                    </div>
                    {schoolData?.parentUrl?.length && (
                      <div className="form-check">
                        <input
                          type="radio"
                          id="parent"
                          name="loginAs"
                          checked={loginAs === `${locationTracker}.parent.fuseclassroom.com`}
                          value={`${locationTracker}.parent.fuseclassroom.com`}
                          onChange={onChangeValue}
                        />
                        <label htmlFor="parent">
                          <img src="/images/parent.svg" alt="Parent" style={{ padding: 11 + "px" }} />
                          Parent
                        </label>
                        <div className="check">
                          <img src="/images/check-ic.svg" alt="Check" />
                        </div>
                      </div>
                    )}

                    {isFuseAILanding() ? null : (
                      <div className="form-check">
                        <input
                          type="radio"
                          id="other"
                          name="loginAs"
                          value={`${locationTracker}.admin.fuseclassroom.com`}
                          checked={loginAs === `${locationTracker}.admin.fuseclassroom.com`}
                          onChange={onChangeValue}
                        />
                        <label htmlFor="other">
                          <img src="/images/others.svg" alt="Other" />
                          Other
                        </label>
                        <div className="check">
                          <img src="/images/check-ic.svg" alt="Check" />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <button type="submit" className={`btn-log ${loginAs === "" ? "btn-disabled" : ""}`}>
                Login
              </button>
            </form>

            <div className="help">
              <img src="/images/help.svg" className="support" alt="help" />
              <span>
                For help email{" "}
                <a href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com">
                  support@fusemachines.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OtherLanding
