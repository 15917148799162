import React, { useState } from "react"
import SEO from "./seo"

const AnkuramLanding = ({ locationTracker }) => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }

  const onChangeValue = event => setLoginAs(event.target.value)

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Fuse Classroom Login"}
        fbDes={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        twitDesc={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        seoLink={"https://ankuram.fuseclassroom.com/"}
        ogImage={"https://fuseclassroom.com/images/ogimage.jpg"}
      />

      <div className="ankuram-landing">
        {/* left side */}
        <div className="left-side">
          <div className="left--header">
            <img alt="ankuram-left" src="images/ankuram/l-t.png" />
          </div>
          <div className="left--content m-l">
            <img alt="ankuram-logo" src="/images/ankuram/logo.png" className="main-logo" />
            <div className="title">
              World Class Education
              <br />
              in Chitwan
            </div>
            <div className="login-details">
              <h5>Login as</h5>
              <form onSubmit={formSubmit} className="login-form">
                <div className="form-check form-check__options">
                  <input
                    type="radio"
                    id="student"
                    name="loginAs"
                    checked={loginAs === `${locationTracker}.student.fuseclassroom.com`}
                    value={`${locationTracker}.student.fuseclassroom.com`}
                    onChange={onChangeValue}
                  />
                  <label htmlFor="student">Student</label>
                </div>
                <div className="form-check form-check__options">
                  <input
                    type="radio"
                    id="other"
                    name="loginAs"
                    value={`${locationTracker}.admin.fuseclassroom.com`}
                    checked={loginAs === `${locationTracker}.admin.fuseclassroom.com`}
                    onChange={onChangeValue}
                  />
                  <label htmlFor="other">Other</label>
                </div>
                <button type="submit" className={`btn-login ${loginAs === "" ? "btn-disabled" : ""}`}>
                  Login
                </button>
              </form>
            </div>
            <div className="help">
                  <img alt="help-icon" src="/images/help-icon.svg"/>
                  <span>For help email <a href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com">support@fusemachines.com</a></span>
            </div>
          </div>
        </div>
        {/* right side */}
        <div className="right-side">
          <div className="right--content">
           <p>
           Bharatpur-7, Krishnapur, Chitwan<br/>
           info@ankuram.edu.np | Contact: 056-418327
           </p>
          </div>
          <div className="footer">
            <div className="footer-triangle">
              <div className="footer--content">
                <img alt="ankuram-globe" src="/images/ankuram/globe-icon.svg" className="icon-globe" />
                <p>www.ankuram.edu.np</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AnkuramLanding
