import React, { useState } from "react"
import { LightenDarkenColor } from "lighten-darken-color"
import SEO from "../seo"

const SecondTemplate = ({ schoolData, locationTracker }) => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }

  const primaryColor = schoolData.primaryColor
  const bgPrimary = {
    backgroundColor: primaryColor,
  }
  const bgSaturated = {
    backgroundColor: schoolData.secondaryColor ? schoolData.secondaryColor : LightenDarkenColor(primaryColor, 10),
  }
  const onChangeValue = event => {
    setLoginAs(event.target.value)
  }
  const parentUrl = schoolData?.parentUrl

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Fuse Classroom Login"}
        fbDes={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        twitDesc={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        seoLink={"https://academy.fuseclassroom.com/"}
        ogImage={"https://fuseclassroom.com/images/ogimage.jpg"}
      />

      <div className="standard-landing-two">
        <div className="d-flex">
          <style dangerouslySetInnerHTML={{ __html: schoolData.dynamicCSS }} />
          <div className="left-side" style={bgPrimary} id="dyn-container">
            <div className="left-content" id="dyn-left-content">
              {schoolData?.dynamicHTML ? (
                <div dangerouslySetInnerHTML={{ __html: schoolData?.dynamicHTML }}></div>
              ) : (
                <>
                  <div className="main-logo" id="dyn-main-logo">
                    <img alt="collegename-logo" id="dyn-img" src={schoolData.logo} />
                  </div>
                  <div className="title" id="dyn-title">
                    {schoolData.tagLine}
                  </div>

                  <div className="login-details" id="dyn-login-details" style={bgSaturated}>
                    <h5>Login as</h5>
                    <form onSubmit={formSubmit}>
                      <div className="forms" id="dyn-forms">
                        {schoolData?.loginTypes ? (
                          schoolData.loginTypes.map(type => (
                            <div className="form-check same" id="dyn-input">
                              <input
                                type="radio"
                                id={type?.label}
                                name="loginAs"
                                checked={loginAs === type?.redirectUrl}
                                value={type?.redirectUrl}
                                onChange={onChangeValue}
                              />
                              <label htmlFor={type?.label}>{type?.label}</label>
                            </div>
                          ))
                        ) : (
                          <>
                            <div className="form-check same" id="dyn-input">
                              <input
                                type="radio"
                                id="student"
                                name="loginAs"
                                checked={loginAs === schoolData.studentUrl}
                                value={schoolData.studentUrl}
                                onChange={onChangeValue}
                              />
                              <label htmlFor="student">Student</label>
                            </div>
                            {parentUrl?.length && (
                              <div className="form-check same" id="dyn-input">
                                <input
                                  type="radio"
                                  id="parent"
                                  name="loginAs"
                                  checked={loginAs === parentUrl}
                                  value={parentUrl}
                                  onChange={onChangeValue}
                                />
                                <label htmlFor="parent">Parent</label>
                              </div>
                            )}
                            <div className="form-check same" id="dyn-input">
                              <input
                                type="radio"
                                id="other"
                                name="loginAs"
                                value={schoolData.adminUrl}
                                checked={loginAs === schoolData.adminUrl}
                                onChange={onChangeValue}
                              />
                              <label htmlFor="other">Other</label>
                            </div>
                          </>
                        )}
                      </div>
                      <button
                        type="submit"
                        className={`btn-log ${loginAs === "" ? "btn-disabled" : ""}`}
                        disabled={loginAs === ""}
                        style={{ color: primaryColor }}
                        id="dyn-btn-log"
                      >
                        Login
                      </button>
                    </form>
                  </div>
                  <div className="help" id="dyn-help">
                    <img alt="help-icon" src="/images/help-icon.svg" />
                    <span>
                      For help email &nbsp;
                      <a href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com">
                        support@fusemachines.com
                      </a>
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="right-side" id="dyn-right-side">
            <img alt="" className="img-responsive" src={schoolData.backgroundImage} />
          </div>
        </div>
      </div>
    </>
  )
}

export default SecondTemplate
