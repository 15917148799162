import React, { useState } from "react"
import SEO from "../components/seo"

const TrinityLanding = ({ locationTracker }) => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }

  const onChangeValue = event => {
    setLoginAs(event.target.value)
  }

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Fuse Classroom Login"}
        fbDes={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        twitDesc={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        seoLink={"https://trinity.fuseclassroom.com/"}
        ogImage={"https://fuseclassroom.com/images/ogimage.jpg"}
      />

      <div className="trinity-landing">
        {/* left side */}
        <div className="left-side">
          <div className="left-content">
            <div className="main-logo">
              <img src="./images/trinity/logo-white.png" className="logo" alt="logo" />
            </div>
            <div className="title">Trinity Education Platform</div>
            <div className="sub-title">Start your Digital College Experience.</div>
          </div>
        </div>

        {/* right side */}
        <div className="right-side">
          <div className="login-details">
            <h5>Select your role to login</h5>
            <form onSubmit={formSubmit}>
              <div className="forms">
                <div className="form-check">
                  <input
                    type="radio"
                    id="student"
                    name="loginAs"
                    checked={loginAs === `${locationTracker}.student.fuseclassroom.com`}
                    value={`${locationTracker}.student.fuseclassroom.com`}
                    onChange={onChangeValue}
                  />
                  <label htmlFor="student">
                    <img src="/images/trinity/student.svg" alt="Student" />
                    Student
                  </label>
                  <div className="check">
                    <img src="/images/trinity/check-ic.svg" alt="Check" />
                  </div>
                </div>

                <div className="form-check">
                  <input
                    type="radio"
                    id="other"
                    name="loginAs"
                    value={`${locationTracker}.admin.fuseclassroom.com`}
                    checked={loginAs === `${locationTracker}.admin.fuseclassroom.com`}
                    onChange={onChangeValue}
                  />
                  <label htmlFor="other">
                    <img src="/images/trinity/others.svg" alt="Other" />
                    Other
                  </label>
                  <div className="check">
                    <img src="/images/trinity/check-ic.svg" alt="Check" />
                  </div>
                </div>
              </div>
              <button type="submit" className={`btn-log ${loginAs === "" ? "btn-disabled" : ""}`}>
                Login
              </button>
            </form>

            <div className="help">
              <img src="/images/trinity/help.svg" className="support" alt="help" />
              <span>
                For help email{" "}
                <a href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com">
                  support@fusemachines.com
                </a>
              </span>
            </div>
          </div>

          <div className="main-logo">
            <img src="./images/trinity/trinity-logo.png" className="logo" alt="logo" />
          </div>
          <div className="footer">
            <p> Education for the Future</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default TrinityLanding
