import axios from "axios"

const config = {
  rest_url: process.env.GATSBY_APP_REST_URL,
}
axios.defaults.baseURL = config.rest_url

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default axios
