import React from "react"

export default function TerminatedPage() {
  return (
    <>
    <div className="service-terminated">
        <img src="/images/serviceterminated.svg" />
     </div>
    </>
  )
  
}
