import React, { useState } from "react"
import SEO from "../seo"

const FirstTemplate = ({ schoolData, locationTracker }) => {
  const [loginAs, setLoginAs] = useState("")

  const formSubmit = event => {
    event.preventDefault()

    const browser = typeof window !== "undefined" && window
    if (browser) {
      browser.location.href = loginAs
    }
  }
  const primaryColor = schoolData.primaryColor
  const bgStandard = {
    backgroundImage: `url(${schoolData.backgroundImage})`,
    boxShadow: `inset 0 0 0 2000px ${primaryColor}66`,
  }
  const bgPrimary = {
    backgroundColor: primaryColor,
  }
  const onChangeValue = event => {
    setLoginAs(event.target.value)
  }
  const parentUrl = schoolData?.parentUrl

  return (
    <>
      <SEO
        title={"AI Enabled Education Platform"}
        titleDesc={"Fuse Classroom Login"}
        fbDes={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        twitDesc={"Fuse Classroom empowers institutions with an AI-enabled education platform."}
        seoLink={"https://academy.fuseclassroom.com/"}
        ogImage={"https://fuseclassroom.com/images/ogimage.jpg"}
      />

      <div className="standard-landing-one">
        <style dangerouslySetInnerHTML={{ __html: schoolData.dynamicCSS }} />
        {/* left side */}

        <div className="background" style={bgStandard} id="dyn-container">
          {schoolData?.dynamicHTML ? (
            <div dangerouslySetInnerHTML={{ __html: schoolData?.dynamicHTML }}></div>
          ) : (
            <div
              className="white-block"
              style={{ backgroundColor: schoolData.secondaryColor ? schoolData.secondaryColor : "white" }}
              id="dyn-white-block"
            >
              <div className="logo" id="dyn-logo">
                <img src={schoolData.logo} alt="logo" />
              </div>
              <div className="title" id="dyn-title">
                {schoolData.tagLine}
              </div>

              <div className="login-details" id="dyn-login-details">
                <h5>Login as</h5>

                <form onSubmit={formSubmit}>
                  <div className="forms" id="dyn-forms">
                    {schoolData?.loginTypes ? (
                      schoolData.loginTypes.map(type => (
                        <div className="form-check same">
                          <input
                            type="radio"
                            id={type?.label}
                            name="loginAs"
                            checked={loginAs === type?.redirectUrl}
                            value={type?.redirectUrl}
                            onChange={onChangeValue}
                          />
                          <label htmlFor={type?.label}>{type?.label}</label>
                        </div>
                      ))
                    ) : (
                      <>
                        <div className="form-check same">
                          <input
                            type="radio"
                            id="student"
                            name="loginAs"
                            checked={loginAs === schoolData.studentUrl}
                            value={schoolData.studentUrl}
                            onChange={onChangeValue}
                          />
                          <label htmlFor="student">Student</label>
                        </div>
                        {parentUrl?.length && (
                          <div className="form-check same">
                            <input
                              type="radio"
                              id="parent"
                              name="loginAs"
                              checked={loginAs === parentUrl}
                              value={parentUrl}
                              onChange={onChangeValue}
                            />
                            <label htmlFor="parent">Parent</label>
                          </div>
                        )}
                        <div className="form-check same">
                          <input
                            type="radio"
                            id="other"
                            name="loginAs"
                            value={schoolData.adminUrl}
                            checked={loginAs === schoolData.adminUrl}
                            onChange={onChangeValue}
                          />
                          <label htmlFor="other">Other</label>
                        </div>
                      </>
                    )}
                  </div>
                  <button
                    type="submit"
                    className={`btn-log ${loginAs === "" ? "btn-disabled" : ""}`}
                    disabled={loginAs === ""}
                    style={bgPrimary}
                    id="dyn-btn-log"
                  >
                    Login
                  </button>
                </form>
                <div className="help" id="help">
                  <img src="/images/help-standard.svg" className="support" alt="help" />
                  <span>
                    For help email{" "}
                    <a href="mailto:support@fusemachines.com?Subject=message%20to%support@fusemachines.com">
                      support@fusemachines.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default FirstTemplate
